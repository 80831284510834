import React from 'react';
import {
  Text,
  Flex,
  Img,
  Link,
  ListItem,
  UnorderedList,
  OrderedList,
} from '@chakra-ui/react';
import '../AboutUs/AboutUs.css';

const Sustain = () => {
  return (
    <Flex direction={'column'} marginTop={10}>
      <Text className='aboutus'>Sustainability </Text>
      <Flex className='line'></Flex>
      <Flex className='intro_card' marginBottom={5}>
        {/* <img src={contentimg} alt='content_img' /> */}
        <Flex className='transbox' direction={'column'}>
          <Text className='sustain_content' mt={20}>
            {/* Green Credentials including extensive graze of livestock like long term leys – little artificial fertilizer – deep rooting grasses which creates carbon sink – due to photosynthesis of the growing green grass and the carbon passes through the leaves to the roots and is stored in the soil. Grass leys is rich in clover which fixes nitrogen from the atmosphere, reducing reliance on artificial fertilizers. All packaging is recyclable. Spent botanicals and still waste is composted on site/farm and spread for next year’s crops – barley, neeps, turnips, bitter oats, grass for graze, hay and silage.<br /> */}
            Committed to carbon zero by 2030. Working towards certified B
            corporation, feasibility studies we are undertaking includes,
          </Text>

          <Text className='sustain_bullet'>
            {' '}
            A) rainwater collection for use in our still cooling systems
            eliminating water wastage in our production process{' '}
          </Text>
          <Text className='sustain_bullet'>
            {' '}
            B) in conjunction with zero waste Scotland the addition of ground
            source heating, and or the use of solar panels for electricity
            production in tandem with our wind turbine
          </Text>
          <Text className='sustain_content changeStyle'>Read More... </Text>
          <UnorderedList>
            <ListItem>
              {' '}
              <Link
                textDecoration='none'
                color={'navy'}
                fontSize='16px'
                pt='0'
                fontWeight={'bold'}
                href='https://tg-roehillsprings.tk/220629 - Roehill Business Carbon Management Plan - Final (1).pdf'
                isExternal
              >
                CARBON MANAGEMENT PLAN
              </Link>{' '}
            </ListItem>
            <ListItem pt='10' pb='20'>
              {' '}
              <Link
                textDecoration='none'
                pt='20'
                color={'navy'}
                fontSize='16px'
                fontWeight={'bold'}
                href='https://tg-roehillsprings.tk/220629 - Roehill Business Carbon Assessment 2021 - Final (1).pdf'
                isExternal
              >
                BUSINESS CARBON ASSESSMENT – 2021
              </Link>{' '}
            </ListItem>
          </UnorderedList>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Sustain;
